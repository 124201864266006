import {TemplateType} from "./index";

export const TemplateBacaliaMarketAdmin: TemplateType[] = [
    {id: "M1"},
    {id: "M2"},
    {id: "M3"},
    {id: "M4"},
    {id: "M5"},
    {id: "M6"},
    {id: "M7"},
    {id: "M8"},
    {id: "M9"},
    {id: "M10"},
    {id: "M11"},

    {id: "P1", content: null},
    {id: "P3", content: null},
    {id: "P3.3"},
    {id: "P3.4"},

    {id: "B1"},
    {id: "B2"},
    {id: "B3"},
    {id: "B4"},
    {id: "B5"},
    {id: "B6"},
    {id: "B7"},
    {id: "B8"},
    {id: "B9"},
    {id: "B10"},
    {id: "B11"},
    {id: "B12"},
    {id: "B13"},
    {id: "B14"},
    {id: "B15"},
    {id: "B16"},
    {id: "B17"},
    {id: "B18"},
    {id: "B19"},
    {id: "B20"},
    {id: "B21"},
    {id: "B22"},
    {id: "B23"},
    {id: "B24"},
    {id: "B25"},
    {id: "B26"},
    {id: "B27"},
    {id: "B28"},
    {id: "B29"},
    {id: "B30"},

    {id: "PR1", content: null},
    {id: "PR2", content: null},

    {id: "X1"},
    {id: "X2"},
    {id: "X3"},
    {id: "X4"},

    {id: "C", content: null},
    {id: "EA", content: null},
];

export const TemplateFishMarketAdmin: TemplateType[] = [
    {id: "M1"},
    {id: "M2"},
    {id: "M3"},
    {id: "M4"},
    {id: "M5"},
    {id: "M6"},
    {id: "M7"},
    {id: "M8"},
    {id: "M9"},
    {id: "M10"},
    {id: "M11"},
    {id: "M14"},

    {id: "X1"},
    {id: "X2"},
    {id: "X3"},
    {id: "X4"},
    {id: "X5"},
    {id: "X6"},
    {id: "X7"},

    {id: "OP"},
    {id: "MT", content: null},

    {id: "T1"},
    {id: "T2"},

    {id: "Cafe"},
    {id: "C"},
];

export const TemplateManufacturyMarketAdmin: TemplateType[] = [
    {id: "K5.1"},
    {id: "K5.2"},
    {id: "K6.1"},
    {id: "K6.2"},
    {id: "K6.3"},
    {id: "K6.4"},
    {id: "K6.5"},
    {id: "K6.6"},
    {id: "K6.7"},
    {id: "K6.8"},
    {id: "K6.9"},
    {id: "K6.10"},
    {id: "K6.11"},
    {id: "K6.12"},
    {id: "K6.13"},
    {id: "K6.14"},
    {id: "K7.1"},
    {id: "K7.2"},
    {id: "K7.3"},
    {id: "K7.4"},
    {id: "K7.5"},
    {id: "K7.6"},
    {id: "K7.7"},
    {id: "K7.8"},
    {id: "K7.9"},
    {id: "K7.10"},
    {id: "K7.11"},
    {id: "K7.12"},
    {id: "K7.13"},
    {id: "K7.14"},
    {id: "K8.1"},
    {id: "K8.2"},
    {id: "K8.3"},
    {id: "K8.4"},
    {id: "K8.5"},
    {id: "K8.6"},
    {id: "K8.7"},
    {id: "K8.8"},
    {id: "K8.9"},
    {id: "K8.10"},
    {id: "K8.11"},
    {id: "K8.12"},
    {id: "K8.13"},
    {id: "K8.14"},

    {id: "X4"},
    {id: "X5"},
    {id: "X6"},

    {id: "B", content: null},

    {id: "M1"},
    {id: "M2"},

    {id: "UkrPost", content: null},
    {id: "NovaPost"},
    {id: "Cafe"},
];

export const TemplateMeatMarketAdmin: TemplateType[] = [
    {id: "M1.1"},
    {id: "M1.2"},
    {id: "M1.3"},
    {id: "M1.4"},
    {id: "M1.5"},
    {id: "M2"},
    {id: "M3"},
    {id: "M4"},
    {id: "M5"},
    {id: "M6"},
    {id: "M7"},

    {id: "B1"},
    {id: "B2"},
    {id: "B3"},
    {id: "B4"},
    {id: "B5"},
    {id: "B6"},
    {id: "B7"},
    {id: "B8"},
    {id: "B9"},
    {id: "B10"},
    {id: "B11"},
    {id: "B12"},
    {id: "B13"},
    {id: "B14"},
    {id: "B15"},
    {id: "B16"},
    {id: "B17"},
    {id: "B18"},
    {id: "B19"},
    {id: "B20"},
    {id: "B21"},
    {id: "B22"},
    {id: "B23"},
    {id: "B24"},
    {id: "B25"},
    {id: "B26"},
    {id: "B27"},
    {id: "B28"},
    {id: "B29"},
    {id: "B30"},
    {id: "B31"},
    {id: "B32"},
    {id: "B33"},
    {id: "B34"},
    {id: "B35"},
    {id: "B36"},
    {id: "B37"},
    {id: "B38"},
    {id: "B39"},
    {id: "B40"},
    {id: "B41"},
    {id: "B42"},
    {id: "B43"},
    {id: "B44"},
    {id: "B45"},
    {id: "B46"},
    {id: "B47"},
    {id: "B48"},

    {id: "PR1", content: null},
    {id: "PR2", content: null},

    {id: "X1"},
    {id: "X2"},
    {id: "X3"},
    {id: "X4"},
    {id: "X5"},
    {id: "X6"},
    {id: "X7"},
    {id: "X8"},
    {id: "X9"},

    {id: "BT", content: null},
    {id: "P", content: null},
    {id: "C1"},
    {id: "K1"},
];
