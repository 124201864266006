import {TooltipContentType} from "components/Tooltip";
import {externalLink} from "config/externalLink";

export type TemplateType = {
    id: string | number
    content?: TooltipContentType | null
    color?: string
    disabled?: boolean
    free?: boolean
}

export const TemplatePochatok: TemplateType[] = [
    {
        id: 1,
        content: {
            name: "fruits-vegetables-market",
            shop_time_work: "08:00 - 19:00",
            navLink: "fruits-vegetables-market",
        },
    },
    {
        id: 2,
        content: {
            name: "fish-market",
            shop_time_work: "07:00 - 21:00",
            navLink: "fish-market",
        },
    },
    {
        id: 3,
        content: {
            name: "bacalia-market",
            shop_time_work: "08:00 - 19:00",
            navLink: "bacalia-market",
        },
    },
    {
        id: 4,
        content: {
            name: "bacalia-market",
            shop_time_work: "09:00 - 16:00",
            navLink: "gallery-food",
        },
    },
    {
        id: 5,
        content: {
            name: "meat-market",
            shop_time_work: "07:00 - 17:00",
            navLink: "meat-market",
        },
    },
    {id: 6},
    {
        id: 7,
        content: {
            name: "Administration",
            shop_time_work: "09:00 - 16:00",
            external_link: externalLink.Administration,
        },
    },
    {
        id: 8,
        content: {
            name: "Parking for cars",
            external_link: externalLink.Parking,
        },
    },
    {
        id: 9,
        content: {
            name: "Weighing",
        },
    },
    {
        id: 10,
        content: {
            name: "covered-market",
            shop_time_work: "05:00 - 23:00",
            navLink: "covered-market",
        },
    },
    {
        id: 11,
        content: {
            name: "pavilion-ukraine",
            shop_time_work: "05:00 - 23:00",
            navLink: "pavilion-ukraine",
        },
    },
    {
        id: 12,
        content: {
            name: "pavilion-odesa",
            shop_time_work: "05:00 - 23:00",
            navLink: "pavilion-odesa",
        },
    },
    {
        id: 13,
        content: {
            name: "Parking for trucks",
            external_link: externalLink.ParkingTrucks,
        },
    },
    {
        id: 14,
        content: {
            name: "manufactury-market",
            shop_time_work: "08:00 - 17:00",
            navLink: "manufactury-market",
        },
    },
];

export const TemplateFruitsVegetablesMarket: TemplateType[] = [
    {id: "1"},
    {id: "2"},
    {id: "3"},
    {id: "4"},
    {id: "5"},
    {id: "6"},
    {id: "7"},
    {id: "8"},
    {id: "9"},
    {id: "10"},
    {id: "11"},
    {id: "12"},
    {id: "13"},
    {id: "13A"},
    {id: "14"},
    {id: "15"},
    {id: "16"},
    {id: "17"},
    {id: "18"},
    {id: "19-20"},
    {id: "22"},
    {id: "23"},
    {id: "23A"},
    {id: "24"},
    {id: "25"},

    {id: "M1"},

    {id: "Coffee"},
    {id: "Bakery"},
];

export const TemplatePavilionUkraine: TemplateType[] = [
    {id: "29"},
    {id: "29A"},
    {id: "30"},
    {id: "31"},
    {id: "32"},
    {id: "33"},
    {id: "34"},
    {id: "35"},
    {id: "36"},
    {id: "37"},
    {id: "38"},
    {id: "39-40"},
    {id: "41-42"},
    {id: "43-44"},
    {id: "45-46"},
    {id: "47-48"},
    {id: "49-50"},
    {id: "51-52"},
    {id: "53-54"},
    {id: "55-56"},
    {id: "57-58"},

    {id: "91"},
    {id: "92"},
    {id: "93"},
    {id: "94"},
    {id: "95"},
    {id: "96"},
    {id: "97"},
    {id: "98"},
    {id: "99"},
    {id: "100"},
    {id: "101"},
    {id: "102"},
    {id: "103"},
    {id: "104"},
    {id: "105"},
    {id: "106"},
    {id: "107"},
    {id: "108"},
    {id: "109"},
    {id: "110"},
    {id: "111"},
    {id: "112"},
    {id: "113"},
    {id: "114"},
    {id: "115"},
    {id: "116"},
    {id: "117"},
    {id: "118"},
    {id: "119"},
    {id: "120"},
    {id: "121"},
    {id: "122"},
    {id: "123"},
    {id: "124"},
    {id: "125"},
    {id: "126"},
    {id: "127"},
    {id: "128"},
    {id: "129"},
    {id: "130"},
    {id: "131"},
    {id: "132"},

    {id: "M1"},
    {id: "M2"},
    {id: "M3"},

    {id: "Cafe"},
];

export const TemplatePavilionOdesa: TemplateType[] = [
    {id: "133"},
    {id: "134"},
    {id: "135"},
    {id: "136"},
    {id: "137"},
    {id: "138"},
    {id: "139"},
    {id: "140"},
    {id: "141"},
    {id: "142"},
    {id: "143"},
    {id: "144"},
    {id: "145"},
    {id: "146"},
    {id: "147"},
    {id: "148"},
    {id: "149"},
    {id: "150"},
    {id: "151"},
    {id: "152"},
    {id: "153"},
    {id: "154"},
    {id: "155"},
    {id: "156"},
    {id: "157"},
    {id: "158"},
    {id: "159"},
    {id: "160"},
    {id: "161"},
    {id: "162"},
    {id: "163"},
    {id: "164"},
    {id: "165"},
    {id: "166"},
    {id: "167"},
    {id: "168"},
    {id: "169"},
    {id: "170"},
    {id: "171"},
    {id: "172"},
    {id: "173"},
    {id: "174"},
    {id: "175"},
    {id: "176"},
    {id: "177"},
    {id: "178"},
    {id: "179"},
    {id: "180"},
    {id: "181"},
    {id: "182"},
    {id: "183"},
    {id: "184"},
    {id: "185"},
    {id: "186"},
    {id: "187"},
    {id: "188"},
    {id: "189"},
    {id: "190"},
    {id: "191"},
    {id: "192"},
    {id: "193"},
    {id: "194"},
    {id: "195"},
    {id: "196"},
    {id: "197"},
    {id: "198"},
    {id: "199"},
    {id: "200"},
    {id: "201"},
    {id: "202"},
    {id: "203"},
    {id: "204"},
    {id: "205"},
    {id: "206"},
    {id: "207"},
    {id: "208"},
    {id: "209"},
    {id: "210"},
    {id: "211"},
    {id: "212"},

    {id: "M1"},

    {id: "Cafe"},
];

export const TemplateBacaliaMarket: TemplateType[] = [
    {id: "M1"},
    {id: "M2"},
    {id: "M3"},
    {id: "M4"},
    {id: "M5"},
    {id: "M6"},
    {id: "M7"},
    {id: "M8"},
    {id: "M9"},
    {id: "M10"},
    {id: "M11"},

    {id: "P1", content: null},
    {id: "P3", content: null},
    {id: "P3.3"},
    {id: "P3.4"},

    {id: "B1"},
    {id: "B2"},
    {id: "B3"},
    {id: "B4"},
    {id: "B5"},
    {id: "B6"},
    {id: "B7"},
    {id: "B8"},
    {id: "B9"},
    {id: "B10"},
    {id: "B11"},
    {id: "B12"},
    {id: "B13"},
    {id: "B14"},
    {id: "B15"},
    {id: "B16"},
    {id: "B17"},
    {id: "B18"},
    {id: "B19"},
    {id: "B20"},
    {id: "B21"},
    {id: "B22"},
    {id: "B23"},
    {id: "B24"},
    {id: "B25"},
    {id: "B26"},
    {id: "B27"},
    {id: "B28"},
    {id: "B29"},
    {id: "B30"},

    {id: "E", content: null},
];

export const TemplateFishMarket: TemplateType[] = [
    {id: "M1"},
    {id: "M2"},
    {id: "M3"},
    {id: "M4"},
    {id: "M5"},
    {id: "M6"},
    {id: "M7"},
    {id: "M8"},
    {id: "M9"},
    {id: "M10"},
    {id: "M11"},
    {id: "M14"},

    {id: "Cafe"},

    {id: "T1"},
    {id: "T2"},

    {id: "E", content: null},
];

export const TemplateMeatMarket: TemplateType[] = [
    {id: "M1.1"},
    {id: "M1.2"},
    {id: "M1.3"},
    {id: "M1.4"},
    {id: "M1.5"},
    {id: "M2"},
    {id: "M3"},
    {id: "M4"},
    {id: "M5"},
    {id: "M6"},
    {id: "M7"},

    {id: "B1"},
    {id: "B2"},
    {id: "B3"},
    {id: "B4"},
    {id: "B5"},
    {id: "B6"},
    {id: "B7"},
    {id: "B8"},
    {id: "B9"},
    {id: "B10"},
    {id: "B11"},
    {id: "B12"},
    {id: "B13"},
    {id: "B14"},
    {id: "B15"},
    {id: "B16"},
    {id: "B17"},
    {id: "B18"},
    {id: "B19"},
    {id: "B20"},
    {id: "B21"},
    {id: "B22"},
    {id: "B23"},
    {id: "B24"},
    {id: "B25"},
    {id: "B26"},
    {id: "B27"},
    {id: "B28"},
    {id: "B29"},
    {id: "B30"},
    {id: "B31"},
    {id: "B32"},
    {id: "B33"},
    {id: "B34"},
    {id: "B35"},
    {id: "B36"},
    {id: "B37"},
    {id: "B38"},
    {id: "B39"},
    {id: "B40"},
    {id: "B41"},
    {id: "B42"},
    {id: "B43"},
    {id: "B44"},
    {id: "B45"},
    {id: "B46"},
    {id: "B47"},
    {id: "B48"},

    {id: "E", content: null},
];

export const TemplateCoveredMarket: TemplateType[] = [
    {id: "29"},
    {id: "29A"},
    {id: "30"},
    {id: "31"},
    {id: "32"},
    {id: "33"},
    {id: "34"},
    {id: "35"},
    {id: "36"},
    {id: "37"},
    {id: "38"},
    {id: "39-40"},
    {id: "41-42"},
    {id: "43-44"},
    {id: "45-46"},
    {id: "47-48"},
    {id: "49-50"},
    {id: "51-52"},
    {id: "53-54"},
    {id: "55-56"},
    {id: "57-58"},

    {id: "Cafe"},
];

export const TemplateManufacturyMarket: TemplateType[] = [
    {id: "K5.1"},
    {id: "K5.2"},
    {id: "K6.1"},
    {id: "K6.2"},
    {id: "K6.3"},
    {id: "K6.4"},
    {id: "K6.5"},
    {id: "K6.6"},
    {id: "K6.7"},
    {id: "K6.8"},
    {id: "K6.9"},
    {id: "K6.10"},
    {id: "K6.11"},
    {id: "K6.12"},
    {id: "K6.13"},
    {id: "K6.14"},
    {id: "K7.1"},
    {id: "K7.2"},
    {id: "K7.3"},
    {id: "K7.4"},
    {id: "K7.5"},
    {id: "K7.6"},
    {id: "K7.7"},
    {id: "K7.8"},
    {id: "K7.9"},
    {id: "K7.10"},
    {id: "K7.11"},
    {id: "K7.12"},
    {id: "K7.13"},
    {id: "K7.14"},
    {id: "K8.1"},
    {id: "K8.2"},
    {id: "K8.3"},
    {id: "K8.4"},
    {id: "K8.5"},
    {id: "K8.6"},
    {id: "K8.7"},
    {id: "K8.8"},
    {id: "K8.9"},
    {id: "K8.10"},
    {id: "K8.11"},
    {id: "K8.12"},
    {id: "K8.13"},
    {id: "K8.14"},

    {id: "M1"},
    {id: "M2"},

    {id: "UkrPost", content: null},
    {id: "NovaPost"},
    {id: "Cafe"},

    {id: "E", content: null},
];
