import React, {useContext, useEffect, useState} from "react";
import SchemaPochatok from "components/schemas/SchemaPochatok";
import PanelTabs, {TabsType} from "./PanelTabs";
import {AdminApi} from "api";
import {MapContext} from "providers/MapProvider";
import {FilterContext} from "providers/FilterProvider";
import {useSchemaTooltip} from "hooks/useSchemaTooltip";
import {TemplatePochatok, TemplateType} from "config/templates";
import {SchematicPochatok} from "config/schematics";
import {ApplicationContext} from "providers/ApplicationProvider";
import {useTagTooltip} from "hooks/useTagTooltip";
import {TagsPhoto} from "config/tags";
import SchemaPhoto from "components/schemas/SchemaPhoto";
import {useTranslation} from "react-i18next";
import i18next from "i18next";
import {FreePlace} from "../Tooltip";
import {generateLink} from "hooks/useGetOrder";

const PanelPochatok = () => {
    const {t} = useTranslation();

    const {application} = useContext(ApplicationContext)
    const {setStatusMap} = useContext(MapContext);
    const {categories} = useContext(FilterContext);

    const [selectedTab, setSelectedTab] = useState<TabsType>("schema");

    const [tooltipsElement, setTooltipsElement] = useState<TemplateType[]>(TemplatePochatok);

    const schemaElements = useSchemaTooltip(SchematicPochatok, tooltipsElement, "pochatok");
    const tagElements = useTagTooltip(TagsPhoto, tooltipsElement, "pochatok");

    const getPlace = async () => {
        try {
            setStatusMap({loading: true, error: null});

            const filteredCategories = categories.filter(category => category.checked);
            const checkedCategories = filteredCategories.map(category => category.value);
            const lang = i18next.language === "ru" ? i18next.language : null;

            const response = await AdminApi.filterMainPlace(checkedCategories);
            const responseShop = await AdminApi.getOrder(6, [], lang);

            const elements: TemplateType[] = TemplatePochatok.map(template => {
                const {id, content} = template;
                const place = response.data.find(item => item.id === id);

                if (!content) {
                    const foundedShop = responseShop.data.shop?.find(shop => shop.name_place === id.toString());

                    const setFreeShopInfo = () => {
                        const webflow_link = generateLink(application, "landing", "/sellers");
                        const admin_link = generateLink(application, "admin", `/admin/order-map/create?type=1&main_place=${id}&name_place=${id}`);

                        return {
                            ...template,
                            disabled: !place,
                            content: {
                                ...FreePlace,
                                admin_link,
                                webflow_link,
                            },
                            free: false
                        };
                    };

                    if (foundedShop) {
                        const {shop_name: name, shop_time_work, id, admin_link, status} = foundedShop;

                        if (status !== 1 && application === "landing") {
                            return setFreeShopInfo();
                        }

                        return {
                            ...template,
                            disabled: !place,
                            content: {
                                name,
                                shop_id: id as number,
                                shop_time_work,
                                admin_link,
                            },
                        }
                    } else {
                        return setFreeShopInfo();
                    }
                }

                if (!place) return {...template, disabled: true};

                const name = template.content!.name as string;
                return {id, content: {...content, name: t(name) as string}};
            });

            setTooltipsElement(elements);

            setStatusMap({loading: false, error: null});
        } catch (error: any) {
            setStatusMap({loading: false, error: "Temporarily out of service"});
        }
    };

    useEffect(() => {
        getPlace();
    }, [categories]);

    return (
        <>
            {
                application === "landing" && <PanelTabs selected={selectedTab} setSelected={setSelectedTab}/>
            }

            {
                selectedTab === "photo" && <SchemaPhoto elements={tagElements}/>
            }

            {
                selectedTab === "schema" && <SchemaPochatok elements={schemaElements}/>
            }
        </>
    );
};

export default PanelPochatok;
